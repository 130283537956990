import React from "react"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import ProfileImage from "../components/imagesComponents/ProfileImage"
import SEO from "../components/seo"
import indexStyle from '../styles/index.module.css'
import mystoryStyle from '../styles/mystory.module.css'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const MystoryPage = () => (
  <Layout>
    <SEO title="About" />
    <body className={indexStyle.font} >
    <Container style={background}>
      <Row>
        <Col sm={12} md={1}/>
        <Col sm={12} md={3}>
          <ProfileImage />
        </Col>
        <Col sm={12} md={7} style={{textAlign:'justify'}}>
              <h3 className={mystoryStyle.title}>How it Developed</h3> 
              <p >
                  Over the years my parents needed help with medical conditions that included rearranging living
                  spaces and eventually downsizing, and it fell in large part to me. I was in my 30’s when my
                  father was diagnosed with brain cancer, and I gladly interrupted my lifestyle to accommodate
                  my parent’s needs, to honor them. That desire did not eradicate what was the short term
                  emotional and monetary drain of that endeavor, although the reward was the solace of knowing
                  I did what I could as their health declined.
              </p>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
      <Row>
        <Col sm={12} md={{span:10, offset:1}}>
          <div style={{textAlign:'justify', marginTop:'25px'}}>
          <p>My eight siblings are fine people and had good intentions, but because most of them lived out of
          town or state, time constraints or jobs restricted their involvement. Still others were emotionally
          distanced. So, having several siblings does not ensure they all can or will participate in the
          overwhelming task of assisting parents in the various phases of aging.</p>
          <p>My brothers and sisters were unaware of the time and patience necessary to facilitate good
          communication with my parents, doctors, various administrators, and family during the medical
          events and mental processes that occurred. My real estate career came in handy allowing me
          the flexibility to transport them, attend their appointments, and problem solve issues. I used
          many of the same tools and approaches in my professional work with my parents, only this time
          the emotional interaction was up close and personal.</p>
          <p>After my father died, we were faced with years of Mom struggling with her loss. She embarked
          on many challenging scenarios which further complicated matters. When she needed to
          diminish her belongings and move into a studio apartment, I found that directing your own
          mother is a delicate art. I developed a process to help her sort through her belongings to
          complete a list of things we would need for her new home. That experience taught me to see
          things from her perspective and work patiently with care and compassion as she worked
          through the struggle of letting go of material possessions. It was the beginning of my journey in
          the service of helping others deal with age related housing changes. I was present when both
          my parents died. I count it a great honor to have had the opportunity to hold my mother’s hand
          as she left this life.</p>
          <p>Everyone on our team has similar stories and because of that, we are committed to respecting
          the family’s process and their wishes to successfully reach the desired outcome of these
          important life changes. Those changes may include some or all of the services we offer.
          Whether it is household cleaning; organizing, sorting, compacting accumulated household
          goods; selling household goods; estate simplification or liquidation; preparing and staging a
          home for sale; or selling a home, our professional commitment is to honor the client’s lifetime
          represented in their collected belongings and assist in successfully achieving their goals.</p>
          </div>
        </Col>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default MystoryPage